<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.edit_m", {
                m: $t("message.directionTariff"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <div class="mb-4">
              <el-button type="primary" plain icon="el-icon-money" @click="productPriceList = true">{{$t('message.product_price_list')}}</el-button>
              <el-button type="primary" plain icon="el-icon-money" @click="partnerPriceList = true">{{$t('message.partner_price_list')}}</el-button>
            </div>
            <hr>
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                  <el-col :span="24">
                      <el-form-item
                      prop="name"
                      :label="columns.name.title"
                      >
                        <el-input
                            :placeholder="columns.name.title"
                            v-model="form.name"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="12">
                      <el-form-item
                        prop="from_filial_id"
                        :label="columns.from_filial_id.title"
                        >
                        <select-from-filial 
                          v-model="form.from_filial_id" 
                          :id="form.from_filial_id" 
                          class="mt-2 select__width w-100" 
                        />
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="12">
                      <el-form-item
                      prop="to_filial_id"
                      :label="columns.to_filial_id.title"
                      >
                        <select-to-filial 
                          v-model="form.to_filial_id" 
                          :id="form.to_filial_id" 
                          class="mt-2 select__width w-100" 
                        />
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="18">
                      <el-form-item
                      prop="price_delivery_to_door"
                      :label="columns.price_delivery_to_door.title"
                      >
                        <el-input
                            :placeholder="columns.price_delivery_to_door.title"
                            v-model="form.price_delivery_to_door"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="6">
                      <el-form-item
                        prop="average_days"
                        :label="columns.average_days.title"
                        >
                        <div>
                          <el-input-number
                            type="number"
                            :placeholder="columns.average_days.title"
                            v-model="form.average_days"
                            :min="1"
                          ></el-input-number>
                        </div>
                      </el-form-item>     
                    </el-col>
						   <el-col :span="12">
								<el-form-item>
									<el-checkbox v-model="form.for_systeam" :label="columns.for_systeam.title" border></el-checkbox>
									<el-checkbox v-model="form.for_site" :label="columns.for_site.title" border></el-checkbox>
								</el-form-item>
						  </el-col>
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
    <el-drawer
      :with-header="false"
      :visible.sync="productPriceList"
      class="body_scroll_70 bg-se"
      :append-to-body="true"
      size="70%"
      ref="productPriceList"
      @opened="drawerOpened('productPriceListChild')"
      @closed="drawerClosed('productPriceListChild')"
    >
      <product-price-list
        :selectedItem="model"
        ref="productPriceListChild"
        drawer="productPriceList"
      ></product-price-list>
    </el-drawer>

    <el-drawer
      :with-header="false"
      :visible.sync="partnerPriceList"
      class="body_scroll_70 bg-se"
      :append-to-body="true"
      size="70%"
      ref="partnerPriceList"
      @opened="drawerOpened('partnerPriceListChild')"
      @closed="drawerClosed('partnerPriceListChild')"
    >
      <partner-price-list
        :selectedItem="model"
        ref="partnerPriceListChild"
        drawer="partnerPriceList"
      ></partner-price-list>
    </el-drawer>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";
import productPriceList from "./product-price-list";
import partnerPriceList from "./partner-price-list";

export default {
    name: "directionTariff",
    mixins: [form, drawer, show],
    components: {
      selectFromFilial,
      selectToFilial,
      productPriceList,
      partnerPriceList
    },
    data() {
        return {
          productPriceList: false,
          partnerPriceList: false,
        };
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "directionTariffs/rules",
            model: "directionTariffs/model",
            columns: "directionTariffs/columns",
        }),
    },
    methods: {
        ...mapActions({
            update: "directionTariffs/update",
            show: "directionTariffs/show",
        }),
        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
              this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
        },
        drawerOpened(ref) {        
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.update(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (res.status == 200) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>

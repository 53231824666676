<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.directionTariff"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                      prop="name"
                      :label="columns.name.title"
                      >
                        <el-input
                            :placeholder="columns.name.title"
                            v-model="form.name"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="12">
                      <el-form-item
                        prop="from_filial_id"
                        :label="columns.from_filial_id.title"
                        >
                        <select-from-filial 
                          v-model="form.from_filial_id" 
                          :id="form.from_filial_id" 
                          class="mt-2 select__width w-100" 
                        />
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="12">
                      <el-form-item
                      prop="to_filial_id"
                      :label="columns.to_filial_id.title"
                      >
                        <select-to-filial 
                          v-model="form.to_filial_id" 
                          :id="form.to_filial_id" 
                          class="mt-2 select__width w-100" 
                        />
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="18">
                      <el-form-item
                      prop="price_delivery_to_door"
                      :label="columns.price_delivery_to_door.title"
                      >
                        <el-input
                          type="number"
                          :placeholder="columns.price_delivery_to_door.title"
                          v-model="form.price_delivery_to_door"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->
                    <el-col :span="6">
                      <el-form-item
                        prop="average_days"
                        :label="columns.average_days.title"
                        >
                          <el-input-number
                              :placeholder="columns.average_days.title"
                              v-model="form.average_days"
                              :min="1"
                          ></el-input-number>
                      </el-form-item>     
                    </el-col>

						  <el-col :span="12">
								<el-form-item>
									<el-checkbox v-model="form.for_systeam" :label="columns.for_systeam.title" border></el-checkbox>
									<el-checkbox v-model="form.for_site" :label="columns.for_site.title" border></el-checkbox>
								</el-form-item>
						  </el-col>
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";

export default {
  name: "directionTariff",
  mixins: [form, drawer],
  components: {
    selectFromFilial,
    selectToFilial,
  },
  data() {
    return {

    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "directionTariffs/rules",
          model: "directionTariffs/model",
          columns: "directionTariffs/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "directionTariffs/store",
      }),
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>
